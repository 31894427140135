import React, { useState } from "react";
import ProfileForm from "./ProfileForm";

const InfoForm = ({user}) => {

    return(
        <div className="p-4 mb-4  border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2  sm:p-6 ">
                        <h3 className="mb-4 text-xl font-semibold ">
                            General information, Timezone, and Language
                        </h3>
                        <ProfileForm user={user} />
                    </div>
    );
}

export default InfoForm;