import React from 'react';
import { useSelector } from 'react-redux';
import Logout from '../Authentication/Logout';
import { API_USER_IMAGE } from '../constant/index';
import BackButton from '../components/Buttons/BackButton';
import UserForm from '../components/Profile/ProfileForm';
import ProfilePicture from '../components/Profile/ProfilePicture';
import InfoForm from '../components/Profile/InfoForm';
import PasswordForm from '../components/Profile/PasswordForm';

export default function Profile() {
    const user = useSelector(state => state.user.user);

    const firstName = user && user.name ? user.name.split(" ")[0] : "";
    const lastName = user && user.name ? user.name.split(" ").slice(1).join(" ") : "";

    return (
        <div className="content-wrapper">
            <div className="">
                <BackButton />
            </div>
            <div className="grid grid-cols-1 px-4 pt-6 xl:grid-cols-3 xl:gap-4 content-wrapper">
                <div className="mb-4 col-span-full xl:mb-2">
                    <h3 className="py-5 text-2xl  ">
                        User settings
                    </h3>
                </div>
                {/* Right Content */}
                <ProfilePicture user={user} />
                <div className="col-span-2">
                    <InfoForm user={user} />
                    <PasswordForm user={user} />
                    <div className="p-4 mb-4  border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2  sm:p-6 ">
                        <div className="flow-root">
                            <h3 className="text-xl font-semibold ">Sessions</h3>
                            <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                                <li className="py-4">
                                    <div className="flex items-center space-x-4">
                                        <div className="inline-flex items-center">
                                            <Logout />
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}